.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

a {
  color: #61dafb;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

section {
  margin-top: 2rem;
  text-align: left;
  max-width: 600px;
}

pre {
  background-color: #1e1e1e;
  color: #dcdcdc;
  padding: 1rem;
  border-radius: 5px;
  overflow-x: auto;
}